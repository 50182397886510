import { Button, CardBody, Form, FormGroup, Input } from "reactstrap"
import { Check, Lock, Mail } from "react-feather"

import Checkbox from "../../../../components/@vuexy/checkbox/CheckboxesVuexy"
import { Link } from "react-router-dom"
import React from "react"
import { connect } from "react-redux"
import fetchApi from "../../../../utility/context/fetchApi"
import { getSystemLanguage } from "../../languages"
import { history } from "../../../../history"
import notify from "../../../../utility/context/notify"
import { setValueBinded } from "../../../../redux/actions"

class LoginJWT extends React.Component {
  state = {
    email: "",
    password: "",
    remember: true
  }

  componentDidMount = () => {
    localStorage.clear()
  }

  handleLogin = async e => {
    e.preventDefault()
    const user = await fetchApi({
      url: '/auth/login',
      method: 'POST',
      body: {
        email: this.state.email,
        password: this.state.password
      }
    })
    if (user?.code && user?.message) {
      notify(user?.message, 'error')
      return
    }
    if (user) {
      localStorage.setItem('lang', user?.user?.lang || getSystemLanguage())
      if (this.state.remember) {
        localStorage.setItem('user', JSON.stringify(user))
      }
      this.props.setUser(user)
      history.push("/")
    }
  }
  render() {
    return (
      <div>
        <CardBody className="pt-1">
          <Form action="/">
            <FormGroup className="form-label-group position-relative has-icon-left">
              <Input
                type="email"
                placeholder="Email"
                value={this.state.email}
                onChange={e => this.setState({ email: e.target.value })}
                required
              />
              <div className="form-control-position">
                <Mail size={15} />
              </div>
            </FormGroup>
            <FormGroup className="form-label-group position-relative has-icon-left">
              <Input
                type="password"
                placeholder={getSystemLanguage() === 'RU' ? 'Пароль' : 'Password'}
                value={this.state.password}
                onChange={e => this.setState({ password: e.target.value })}
                required
              />
              <div className="form-control-position">
                <Lock size={15} />
              </div>
            </FormGroup>
            <FormGroup className="d-flex justify-content-between align-items-center">
              <Checkbox
                color="primary"
                icon={<Check className="vx-icon" size={16} />}
                label={getSystemLanguage() === 'RU' ? 'Запомнить' : 'Remember me'}
                checked={this.state.remember}
                onChange={(e) => this.setState({remember: e.target.checked})}
              />
              <div className="float-right">
                <Link to={`/forgot-password${window.location.search}`}>{getSystemLanguage() === 'RU' ? 'Забыли Пароль' : 'Forgot Password'}?</Link>
              </div>
            </FormGroup>
            
            <div className="d-flex justify-content-between">
              <Button.Ripple
                color="primary"
                outline
                onClick={() => {
                  history.push(`/register${window.location.search}`)
                }}
              >
                {getSystemLanguage() === 'RU' ? 'Регистрация' : 'Register'}
              </Button.Ripple>
              <Button.Ripple color="primary" onClick={this.handleLogin} style={{marginLeft: 5}}>
                {getSystemLanguage() === 'RU' ? 'Войти' : 'Login'}
              </Button.Ripple>
            </div>
          </Form>
        </CardBody>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
      langsss: state.app.lang
  }
}

export default connect(mapStateToProps, { setUser: setValueBinded(['user']) })(LoginJWT)
