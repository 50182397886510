import * as Icon from "react-feather"

import languages, { getSystemLanguage } from "../views/pages/languages"

import React from "react"

const horizontalMenuConfig = () => [
  {
    id: "compaigns",
    title: languages[getSystemLanguage()].menu.compaigns,
    type: "item",
    icon: <Icon.List size={20} />,
    navLink: "/campaigns",
  },
  {
    id: "blackList",
    title: languages[getSystemLanguage()].menu.blackList,
    type: "item",
    icon: <Icon.Edit size={20} />,
    navLink: "/black-list",
  },
  {
    id: "knowledgeBase",
    title: languages[getSystemLanguage()].menu.knowledgeBase,
    icon: <Icon.Info size={20} />,
    type: "external-link",
    newTab: true,
    navLink: "https://cloakit.wiki",
  },
  {
    id: "affiliate",
    title: languages[getSystemLanguage()].menu.affiliate,
    type: "item",
    icon: <Icon.GitPullRequest size={20} />,
    navLink: "/affiliate",
  },
  {
    id: "payments",
    title: languages[getSystemLanguage()].menu.payments,
    type: "item",
    icon: <Icon.CreditCard size={20} />,
    navLink: "/payments",
  },
  {
    id: "support",
    title: languages[getSystemLanguage()].menu.support,
    type: "external-link",
    newTab: true,
    icon: <Icon.LifeBuoy size={20} />,
    navLink: "https://t.me/cloakit",
  }
]

export default horizontalMenuConfig
