import { history } from "../../history"
import notify from "./notify"

export const SERVER_ADDRESS_GET = process.env.NODE_ENV === 'production' ? 'https://api.cloakit.pro' : 'https://api.cloakit.pro'

export const SERVER_ADDRESS_MAIN = SERVER_ADDRESS_GET

export default async function fetchApi ({url, method, body, token}) {
  const server = method === 'GET' ? SERVER_ADDRESS_GET : SERVER_ADDRESS_MAIN
    const response = await fetch(`${server}/v1${url}`, {
        method,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: body && JSON.stringify(body)
      })
        .then((resp) => resp.json())
        .then((res) => {
          if (res.code === 401) {
            notify(res.message, 'error')
            history.push('/login')
          }
          return res
        })
        .catch((error) => notify(error.message, 'error'))
      return response
}