import React from "react"
import * as Icon from "react-feather"

const horizontalMenuConfig = [
  {
    id: "compaigns",
    title: "Campaigns",
    type: "item",
    icon: <Icon.List size={16} />,
    navLink: "/campaigns",
  },
  {
    id: "statistics",
    title: "Statistics",
    type: "item",
    icon: <Icon.TrendingUp size={16} />,
    navLink: "/statistics",
  },
  {
    id: "knowledgeBase",
    title: "Knowledge Base",
    type: "item",
    icon: <Icon.CheckSquare size={16} />,
    navLink: "/knowledge-base",
  },
  {
    id: "payments",
    title: "Payments",
    type: "item",
    icon: <Icon.Calendar size={16} />,
    navLink: "/payments",
  },
  {
    id: "affiliate",
    title: "Affiliate",
    type: "item",
    icon: <Icon.Calendar size={16} />,
    navLink: "/affiliate",
  },
  {
    id: "support",
    title: "Support",
    type: "item",
    icon: <Icon.Calendar size={16} />,
    navLink: "/support",
  }
]

export default horizontalMenuConfig
