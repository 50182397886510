import React, { Fragment, useEffect, useState } from "react"
import { Table } from "reactstrap"
import { connect } from "react-redux"
import { setPromocode } from "../../redux/actions"
import { tokenSelector } from "../../redux/selectors"
import fetchApi from "../../utility/context/fetchApi"
import AffiliatePageStat from "./AffiliatePageStat"
import AffiliatePagePayments from "./AffiliatePagePayments"
import notify from "../../utility/context/notify"
import CopyToClipboard from "react-copy-to-clipboard"

const styles = {
    padding: 3,
    fontSize: 14
}

const stylesLeftColumn = {
    width: 170
}

function AffiliatePage(props) {
    const [state, setState] = useState({
        customersCount: 0,
        affiliates: [],
        newPayments: [] 
    })
    const [user, setUser] = useState({})
    const userId = props?.match?.params?.userEmail
    useEffect(() => {
        if (userId) {
            fetchApi({
                url: `/users/${userId}`,
                method: 'GET',
                token: props.token
            }).then((res) => {
                setUser(res)
                fetchApi({
                    url: `/affiliate/stat/${res?.email}`,
                    method: 'GET',
                    token: props.token
                }).then((res) => {
                    setState(res)
                })
            })
        } else {
            fetchApi({
                url: `/affiliate/stat/${props.user.email}`,
                method: 'GET',
                token: props.token
            }).then((res) => {
                setState(res)
            })
        }
    }, [])
    return (
        <Fragment>
            <Table size="sm">
                <tbody>
                    <tr>
                        <th style={{...styles, ...stylesLeftColumn}} scope="row">Promocode</th>
                        <td style={styles}>{user?.affiliate || props.user.affiliate}</td>
                    </tr>
                    <tr>
                        <th style={{...styles, ...stylesLeftColumn}} scope="row">Referral Link</th>
                        <td title='Click to copy' style={{...styles, cursor: 'pointer', textDecoration: 'underline'}}><CopyToClipboard text={`https://cloakit.pro?ref=${user?.affiliate || props.user.affiliate}`}><span onClick={() => notify('Copied Successfully', 'success')}>https://cloakit.pro?ref={user?.affiliate || props.user.affiliate}</span></CopyToClipboard></td>
                    </tr>
                    <tr>
                        <th style={{...styles, ...stylesLeftColumn}} scope="row">Commission</th>
                        <td style={styles}>20%</td>
                    </tr>
                    <tr>
                        <th style={{...styles, ...stylesLeftColumn}} scope="row">Telegram</th>
                        <td style={styles}>{user?.telegram || props.user.telegram}</td>
                    </tr>
                    <tr>
                        <th style={{...styles, ...stylesLeftColumn}} scope="row">USDT TRC20 for Payments</th>
                        <td style={styles}>{user?.wallet || props.user.wallet}</td>
                    </tr>
                    <tr>
                        <th style={{...styles, ...stylesLeftColumn}} scope="row">Customers</th>
                        <td style={styles}>{state.customersCount}</td>
                    </tr>
                    <tr>
                        <th style={{...styles, ...stylesLeftColumn}} scope="row">Total Revenue</th>
                        <td style={styles}>{state.affiliates.filter(({paid}) => paid).reduce((t, { total }) => t + total, 0)}$</td>
                    </tr>
                    <tr>
                        <th style={{...styles, ...stylesLeftColumn}} scope="row">Balance</th>
                        <td style={styles}>{state.affiliates.filter(({paid}) => !paid).reduce((t, { total }) => t + total, 0) + 
                                state.newPayments.reduce((t, { total }) => t + total * 0.2, 0)}$
                        </td>
                    </tr>
                </tbody>
            </Table>
            <AffiliatePageStat dataAll={state.newPayments} />
            <AffiliatePagePayments dataAll={state.affiliates} setDataAll={(affiliates) => setState({ ...state,  affiliates })} />
        </Fragment>
    )
}

const mapStateToProps = state => {
    return {
        user: state.app.user?.user,
        token: tokenSelector(state)
    }
}

export default connect(mapStateToProps, { setPromocode })(AffiliatePage)